.home {
  height: 100vh;
  z-index: 0;
}

.home-title-container {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.home-title {
  display: block;
  position: relative;
  margin-top: 120px;
  animation: fadeInFromLeftAndSmash 1s ease-in-out;
}

/* @media screen and (min-width: 1160px) {
  .home-title h1 {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1500px) {
  .home-title h1 {
    font-size: 6rem;
  }
} */

@keyframes fadeInFromLeftAndSmash {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  70% {
    transform: translateX(50px);
    transform: scale(1.3);
  }

  85% {
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    transform: translateX(0);
  }
}

.home-title-mobile {
  display: none;
}

.home-title-name {
  background-color: white;
  padding: 5px 10px;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: inline-block;
}

.home-title-name h1,
.home-title-name h2 {
  color: rgb(179, 88, 127);
  margin: 0;
}

.home-title-name h2 {
  font-size: 2rem;
}

.home-title-subtitle h2 {
  position: relative;
  font-size: 2.5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.15em;
  border-right: 0.15em solid rgba(255, 255, 255, 0.9);
  animation: typing 3.5s steps(40, end) 1s, opacity 1.2s,
    blink 0.75s step-end infinite;
  width: auto;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.home-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.home-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 100px;
}

.home-button:hover {
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.5);
  transform: translate(0, -3px);
  transition: all 0.2s ease-in-out;
}

.home-button:active {
  box-shadow: none;
  transform: translate(0, 0);
  transition: all 0.2s ease-in-out;
}

.home-button-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px;
  display: none;
}

.home-button-link {
  border: none;
  background-color: #bc5980;

  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.home-button-link:hover {
  background-color: #bc5980;
  color: white;
  border-radius: 0;
}

.home-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 40px 0px;
}

.home-icon-container {
  position: relative;
}

.home-icon-container:hover {
  animation: bounce 0.5s ease-in-out infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
    text-shadow: #7a4d7b 0 0 10px;
  }
  100% {
    transform: translateY(0);
  }
}

.home-icon-float {
  position: absolute;
}
