.nav-items {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  list-style-type: none;
  color: #bc5980;
  margin: 0;
  padding: 0;
}

.nav-items a {
  text-decoration: none;
  color: #bc5980;
  transition: all 0.3s ease-in-out;
}

.nav-links-main,
.nav-links-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.nav-links-social {
  justify-content: center;
  align-items: center;
}
