.projects {
  position: relative;
}

.projects-heading p {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 20px;
  text-align: center;
  color: white;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  margin-top: 20px;
  width: auto;
  padding: 15px;
  justify-content: center;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

.projects-project-container {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.projects-project-container:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), 0 3px 5px rgba(0, 0, 0, 0.22);
}

.projects-project-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.projects-project-container > a {
  text-decoration: none;
  color: #bc5980;
}

.projects-project-container div > h5 {
  margin: 0;
  padding: 20px;
  font-size: 20px;
  color: #bc5980;
}
