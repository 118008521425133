* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  animation: gradient 20s ease infinite 2s;
  background: linear-gradient(
    to bottom right,
    #bd5a80,
    #bd5a80,
    #7a4d7b,
    #bd5a80,
    #7a4d7b,
    #bd5a80,
    #bd5a80,
    #bd5a80
  );
  background-size: 200% 200%;
  padding: 30px;
  background-color: #bd5a80;
  overflow-x: hidden;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

h1 {
  font-size: 4.5rem;
  font-weight: 700;
  margin: 20px 10px 0px 10px;
  text-align: center;
  color: white;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
