.footer {
  margin-top: 120px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.footer-contact a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
}

.footer-contact h2 {
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 0.5rem;
  font-size: 1.1rem;
}
