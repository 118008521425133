.project {
  margin-top: 120px;
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
  margin-top: 25px;
}

.project-links h2 {
  margin: 0;
}

.project-links button {
  margin: 0;
  padding: 10px;
  border: none;
  border-radius: 0.5rem;
  background-color: white;
  color: #bc5980;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.project-links button:hover {
  background-color: transparent;
  color: white;
  transition: all 0.2s ease-in-out;
}

.project-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
  margin-top: 55px;
}

.project-description {
  width: 40%;
  height: 100%;
  padding: 20px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
}

.project-description p {
  margin-top: 10px;
}

.project-key-points {
  padding-left: 15px;
}

.project-key-points li {
  margin-top: 10px;
}

.project-images {
  max-width: 50%;
}
