.about {
  margin-top: 150px;
}
.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
  gap: 50px;
}

.about-description {
  width: 60%;
  height: 100%;
  padding: 20px;
  color: white;
  font-weight: 400;
  font-size: 20px;

  border-radius: 0.5rem;
}
.about-description h2 {
  line-height: 1.5;
}

.about-description p:first-of-type {
  margin-top: 40px;
}

.about-image {
  width: 30%;
}
