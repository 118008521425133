@media screen and (max-width: 700px) {
  h1 {
    font-size: 3.5rem !important;
  }

  /* HOME */
  .home-title {
    display: none;
  }

  .home-title-container {
    text-align: center;
    width: 100%;
    height: 85vh;
    justify-content: space-between;
    margin: 0;
  }

  .home-title-container h2 {
    font-size: 1.3rem;
    margin: 25px 0px 30px 0px;
    /* animation: typing 3.5s steps(40, end), opacity 0s,
      blink 0.75s step-end infinite; */
  }

  .home-title-hello {
    font-size: 2.5rem !important;
    margin: 0px 0px 0px 0px;
  }

  .home-title-mobile {
    display: block;
    width: 100%;
    margin-top: 60px;
  }

  .home-title-subtitle h2 {
    animation: typing 3.5s steps(40, end) 0s, opacity 0s,
      blink 0.75s step-end infinite;
  }

  .home-title-mobile h1 {
    font-size: 3.5rem;
    margin: 20px 0px;
  }

  .home-button-links {
    display: block;
  }

  .home-button-link {
    background-color: transparent;
  }

  /* NAV */
  .projects-nav {
    background-color: white;
  }

  .nav-links-main {
    gap: 0px;
  }

  .nav-items {
    width: 100%;
    padding: 0;
  }

  .projects-nav-items {
    display: none;
  }

  .projects-nav-drawer {
    display: block;
  }

  .projects-nav-drawer-button {
    list-style-type: none;
    color: #bc5980;
    margin-top: 0px;
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 50px;
  }

  .nav-links-social {
    display: none;
  }

  /* ABOUT */

  .about {
    margin-top: 100px;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  }

  .about-description {
    width: 97%;
    border: none;
  }

  .about-description .home-title-name {
    line-height: 4rem;
  }
  .about-image {
    width: 60%;
  }

  /* DRAWER */
  .drawer {
    display: block;
  }

  /* PROJECT */
  .project {
    margin-top: 50px;
  }

  .project-container {
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  }

  .project-description {
    width: 100%;
  }

  .project-images {
    max-width: 100%;
    margin-bottom: 70px;
  }

  .home-icons {
    display: none;
  }
}
