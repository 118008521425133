.drawer {
  margin-top: 70px;
  width: 200px;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 0.5rem;
  display: none;
}

.drawer .projects-nav-item {
  list-style-type: none;
  color: #bc5980;
}

.drawer a {
  text-decoration: none;
}

.drawer .projects-nav-item:hover {
  background-color: #bc5980;
  color: #ffffff;
  cursor: pointer;
  margin: 0;
}

.drawer .active .projects-nav-item {
  background-color: #bc5980;
  color: #ffffff;
  margin: 0;
  padding: 20px;
}
